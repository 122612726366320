import React from "react";
import I18n from 'i18n-js';
import '../../i18n/translations';

const Category = (props) => {
  const { category, category_slug } = props;

  return <React.Fragment>
    <li className={category.parent_slug ? 'pl-3' : ''}>
      <a href={category.path} className={category_slug == category.slug ? 'font-weight-bold' : ''}>
        {category.name}
      </a>
      {category.count ? <small className="text-muted"> ({category.count})</small> : null}
    </li>
  </React.Fragment>;
};

const Categories = (props) => {
  const { categories } = props;

  return <ul className="categories d-none d-md-block">
    <small className="text-muted">{ I18n.t('react.categories') }</small><br />
    {categories.map(root =>
      <Category category={root} key={root.slug} {...props}></Category>
    )}
  </ul>;
}

export default Categories;
