import React, { useRef } from "react";
import I18n from 'i18n-js';

import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";

import {CartItems, CartActions} from './index';

const CartModal = (props) => {
  const { visible, close } = props;
  const form = useRef();
  const submit = () => form.current.submit();

  return (
    <Modal show={visible} animation={false} onHide={close}>
      <Form method="post" action="/cart/update" ref={form}>
        <Modal.Header closeButton>
          <h5 className="m-0">{ I18n.t('react.cart.title') }</h5>
        </Modal.Header>
        <Modal.Body>
          <CartItems {...props} submit={submit}></CartItems>
        </Modal.Body>
        <Modal.Footer>
          <CartActions {...props}></CartActions>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

export default CartModal;