import React, { useState } from "react";

import Product from './Product';
import Filters from './Filters';
import Categories from './Categories';
import Child from './Child';

import Pagination from 'react-bootstrap/Pagination';

const Category = (props) => {
  let [loading, setLoading] = useState(false);
  let [data, setData] = useState(props);

  const { categories, category_slug } = props;
  const { products, filters, pagination } = data;

  const update = (param, value) => {
    setLoading(true);

    const parser = document.createElement('a');
    parser.href = window.location.href;
    parser.pathname += '.json';

    let params = new URLSearchParams(parser.search);
    params.set(param, value);
    parser.search = params.toString();

    fetch(parser.href)
      .then(response => response.json())
      .then(setData)
      .finally(() => setLoading(false));

    parser.pathname = parser.pathname.substring(0, parser.pathname.length - 5);
    window.history.pushState({ turbolinks: true, url: parser.href }, '', parser.href);
  };

  let children = categories.filter(cat => cat.parent_slug == category_slug);
  let pages = pagination.range;

  return (
    <div className="row">
      <div className="col-lg-3 col-md-4 col-12">
        <Filters {...filters} loading={loading} update={update}></Filters>
        <Categories {...data}></Categories>
      </div>
      <div className="col-lg-9 col-md-8 col-12">
        {children.length ? <>
          <div className="row">
            {children.map(cat => (
              <Child key={cat.slug} category={cat}></Child>
            ))}
          </div>
        </> : null}

        <div className="listing-item-container mb-4">
          {products && products.map(product => (
            <Product key={product.code} product={product}></Product>
          ))}
        </div>

        <Pagination className="mb-4">
          {!pages.includes(1) ? <Pagination.Item
            onClick={() => update('page', 1)}
            disabled={!pagination.prev}>
            <i className="far fa-angle-double-left"></i>
          </Pagination.Item> : null}
          <Pagination.Item
            disabled={!pagination.prev}
            onClick={() => update('page', pagination.prev)}>
            <i className="far fa-angle-left"></i>
          </Pagination.Item>
          {pages.map(page =>
            <Pagination.Item
              key={page}
              onClick={() => update('page', page)}>
              {page == pagination.current ? <b>{page}</b> : page}
            </Pagination.Item>
          )}
          <Pagination.Item
            disabled={!pagination.next}
            onClick={() => update('page', pagination.next)}>
            <i className="far fa-angle-right"></i>
          </Pagination.Item>
          {!pages.includes(pagination.total) ? <Pagination.Item
            disabled={!pagination.next}
            onClick={() => update('page', pagination.total)}>
            <i className="far fa-angle-double-right"></i>
          </Pagination.Item> : null}
        </Pagination>
      </div>
    </div>
  );
};

export default Category;
