import React, { useRef, useState } from 'react';
import I18n from 'i18n-js';

import ListGroup from 'react-bootstrap/ListGroup';
import FormControl from 'react-bootstrap/FormControl';

export const Item = ({ item, submit }) => {
  const [toRemove, setToRemove] = useState(false);
  const removeThis = () => {
    setToRemove(true);
    submit();
  };

  return (
    <ListGroup.Item className="cart-item">
      {item.image && <img src={item.image.thumbnail.url} alt="..." width="36"/>}
      <a className="product" href={item.path} tabIndex="-1">
        <div className="title">{item.name}</div>
        <small className="text-muted">{ I18n.t('react.cart.code') }: {item.code}</small>
      </a>
      {!toRemove ?
        <FormControl
          className="cart-count"
          type="number"
          name={`cart[${item.code}]`}
          tabIndex="1"
          defaultValue={item.amount}
        />
      : <input name={`cart[${item.code}]`} type="hidden" value={0} />}
      {submit ?
        <button onClick={removeThis} className="btn btn-link m-0 pl-2 p-0" tabIndex="-1">
          <i className="fas fa-times text-danger"></i>
        </button>
      : null}
    </ListGroup.Item>
  );
};

export const NoItems = () => (
  <ListGroup.Item className="cart-item text-muted">
    { I18n.t('react.cart.no_items') }
  </ListGroup.Item>
);

export const Total = ({ bulk, total, total_vat }) => {
  if (!total && !total_vat) return null;

  return <table className={"mt-3 ml-auto text-right"}>
    <tbody>
      {bulk && total ? <tr className="text-muted">
        <td>{ I18n.t('react.cart.total') }</td>
        <td className="px-2">{total}</td>
      </tr> : null}
      {total_vat ? <tr>
        <td>{ I18n.t('react.cart.total_vat') }</td>
        <td className="px-2 font-weight-bold">{total_vat}</td>
      </tr> : null}
    </tbody>
  </table>;
};
