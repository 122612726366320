// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")

require("jquery")
require("popper.js")
require("bootstrap")
require("../i18n/translations")

var componentRequireContext = require.context("components", true);
var ReactRailsUJS = require("react_ujs");
var I18n = require('i18n-js');

I18n.locale = 'el';
I18n.defaultLocale = 'el';

ReactRailsUJS.useContext(componentRequireContext);

require("./categories")
require("./search_bar");