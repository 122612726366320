import React, { useState } from 'react';

import Modal from "react-bootstrap/Modal";
import FormControl from 'react-bootstrap/FormControl';
import ListGroup from 'react-bootstrap/ListGroup';

const AdminSearchModal = ({visible, close}) => {
  const [results, setResults] = useState([]);

  const search = (event) => {
    const q = event.target.value;

    fetch('/admin/search?q=' + encodeURI(q))
      .then(res => res.json())
      .then(res => setResults(res))
  };

  return (
    <Modal show={visible} animation={false} onHide={close}>
      <Modal.Header closeButton>
        <h5 className="m-0">Αναζήτηση</h5>
      </Modal.Header>
      <Modal.Body>
        <FormControl name="query" onChange={search} placeholder="Εισάγεται όνομα, κωδικο..." autoFocus></FormControl>
        {results.length ? <ListGroup className="mt-2">
          {results.map(res =>
            <ListGroup.Item className="small px-3 py-2" action href={res.h}>
              {res.n}
              <span className="text-muted"> - {res.t}</span>
            </ListGroup.Item>
          )}
        </ListGroup> : null}
      </Modal.Body>
    </Modal>
  );
};

export default AdminSearchModal;