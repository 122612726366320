import React from "react";

const Child = ({ category }) => {
  return <div className="listing-item col-lg-3 col-md-4 col-6 mb-3">
  <a href={category.path}>
    <img src={category.cover.thumbnail.url} alt={category.name} />
    <h5 className="mt-3 mb-0">{category.name}</h5>
  </a>
</div>
}

export default Child;
