import React, { useState } from "react";
import I18n from 'i18n-js';

import Nav from "react-bootstrap/Nav";

import AdminSearchModal from "./Modal";

const AdminSearch = () => {
  let [open, setOpen] = useState(false);
  return (
    <React.Fragment>
      <Nav.Item>
        <Nav.Link onClick={() => setOpen(true)}>
          <i className="far fa-search"></i>
        </Nav.Link>
      </Nav.Item>
      <AdminSearchModal visible={open} close={() => setOpen(false)} />
    </React.Fragment>
  );
};

export default AdminSearch;