import React, { useState } from "react";
import I18n from 'i18n-js';

import Badge from "react-bootstrap/Badge";
import Nav from "react-bootstrap/Nav";

import CartModal from "./Modal";

const Icon = (props) => {
  const { items, cart_open = false } = props;

  let badge = null;
  let [open, setOpen] = useState(cart_open);

  if (items.length > 0) {
    badge = <Badge variant="secondary">{items.length}</Badge>;
  }

  return (
    <React.Fragment>
      <Nav.Item>
        <Nav.Link onClick={() => setOpen(true)}>
          <i className={`${items.length ? 'fas' : 'far'} fa-shopping-cart mr-1`}></i>
          <span className="d-md-none mr-1">{ I18n.t('react.cart.title') }</span>
          {badge}
        </Nav.Link>
      </Nav.Item>
      <CartModal
        {...props}
        visible={open}
        close={() => setOpen(false)}
      />
    </React.Fragment>
  );
};

export default Icon;