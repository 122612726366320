import React from 'react';
import I18n from 'i18n-js';

import Form from "react-bootstrap/Form";
import ListGroup from "react-bootstrap/ListGroup";

import {Item, NoItems, Total} from './utils';

const Update = ({disabled}) => <button
  className={"btn btn-sm btn-outline-secondary " + (disabled ? 'disabled' : '')}
  type="submit"
  tabIndex="4"
  disabled={disabled}
>
  { I18n.t('react.cart.update') }
</button>;

const Clear = ({disabled}) => <a
  href={disabled ? null : "/cart/clear"}
  className={"btn btn-sm btn-outline-danger mr-1 " + (disabled ? 'disabled' : '')}
  tabIndex="3"
>
  { I18n.t('react.cart.empty') }
</a>;

const Checkout = ({disabled}) => <a
  href="/cart/checkout"
  className={"checkout-button btn btn-success " + (disabled ? 'disabled' : '')}
  tabIndex="2"
>
  <i className="fas fa-cash-register"></i>
  <span className="d-none d-sm-inline"> { I18n.t('react.cart.checkout.order') }</span>
</a>;

export const CartItems = (props) => {
  return <React.Fragment>
    <ListGroup>
      {props.items.map(item => (
        <Item key={item.code} item={item} submit={props.submit}></Item>
      ))}
      {props.items.length < 1 && <NoItems></NoItems>}
    </ListGroup>
    {props.items.length > 0 && <Total {...props} />}
  </React.Fragment>
}

export const CartActions = ({ items, in_checkout }) => {
  return <React.Fragment>
    <div className="mr-auto">
      <Clear disabled={items.length < 1}></Clear>
      <Update disabled={items.length < 1}></Update>
    </div>
    <Checkout disabled={items.length < 1}></Checkout>
  </React.Fragment>;
};

const Cart = (props) => <Form method="post" action="/cart/update">
  <CartItems {...props}></CartItems>
  <div className="text-md-right pt-3">
    <Update disabled={props.items.length < 1}></Update>
  </div>
</Form>;

export default Cart;
