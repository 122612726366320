import React from "react";
import I18n from 'i18n-js';

import Form from 'react-bootstrap/Form';

const Filters = ({ update, loading, order_by, price }) => {

  let orderOptions = [['name:asc', I18n.t('react.filter.order.name')]];
  if (price) {
    orderOptions.push(['price_cents:asc', I18n.t('react.filter.order.price')]);
    orderOptions.push(['price_cents:desc', I18n.t('react.filter.order.price_desc')]);
  }
  orderOptions.push(['created_at:desc', I18n.t('react.filter.order.created_at')]);

  const change = (e) => {
    update('order_by', e.target.value);
  };

  return <Form.Group>
    <Form.Control as="select" size="sm"
      value={order_by}
      onChange={change}
      disabled={loading || orderOptions.length < 2}>
      {orderOptions.map(options =>
        <option key={options[0]} value={options[0]}>
          {I18n.t('react.filter.order.by')} {options[1]}
        </option>
      )}
    </Form.Control>
  </Form.Group>;
}

export default Filters;
