import React, { useState, useRef } from "react";
import Modal from "react-bootstrap/Modal";
import I18n from 'i18n-js';

const Carousel = ({ images }) => {
  const [visible, setVisible] = useState(null);

  return <React.Fragment>
    <div className="carousel">
      {images.map((obj, i) => (
        <img
          key={i}
          src={i === 0 ? obj.web.url : obj.thumbnail.url}
          onClick={() => setVisible(images[i].url)}
        />
      ))}
    </div>
    <Modal size="xl" animation={false} show={visible != null} onHide={() => setVisible(null)}>
      <Modal.Body className="image-loupe p-4">
        <button className="times" onClick={() => setVisible(null)}>
          <i className="far fa-times fa-2x"></i>
        </button>
        <img className="w-100" src={visible} alt={I18n.t('react.product.image')}></img>
      </Modal.Body>
    </Modal>
  </React.Fragment>;
};

export default Carousel;
