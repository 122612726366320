import React from "react";

const AddToCart=({product}) => {
  return <form action="/cart/add" method="post">
    <input name="product" value={product.code} type="hidden" />
    <input name="amount" value={1} type="hidden" />
    <button type="submit" className="btn btn-success">
      <i className="fas fa-shopping-cart"></i>
    </button>
  </form>
};

const Product = ({ product }) => {
  const price = product.price ? (
    <span class="w-100 w-md-auto">{product.price}</span>
  ): null;
  const availability = product.available ? (
    <span className="text-success">Διαθέσιμο</span>
  ) : (
    <span className="text-danger text-nowrap">Μη διαθέσιμο</span>
  );

  let image;
  if (product.image) {
    image = product.image.thumbnail.url;
  }

  return (
    <div className="listing-item">
      <a href={product.path}>
        <div className="product_image">
          <img src={image} alt={product.name} />
        </div>
      </a>
      <div className="description">
        <a href={product.path}>
          <h5 className="cf title mb-1">{product.name}</h5>
        </a>
        <div>
          {price}
          {availability}
          {product['can_purchase?'] ? <AddToCart product={product} /> : null}
        </div>
      </div>
    </div>
  );
};

export default Product;
